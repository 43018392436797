import { render, staticRenderFns } from "./CollegeResetPassword.vue?vue&type=template&id=e851aa16&scoped=true&"
import script from "./CollegeResetPassword.vue?vue&type=script&lang=js&"
export * from "./CollegeResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./CollegeResetPassword.vue?vue&type=style&index=0&id=e851aa16&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e851aa16",
  null
  
)

export default component.exports